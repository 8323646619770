import React from "react"
import { Link } from "gatsby"
import { CallToActionConfig } from "../../config/config-data"


const CallToAction = ({ ModalState, GradientAnimation }) => (

  <>
    <section className="Section_Container" id={CallToActionConfig.section_header}>
      {CallToActionConfig.section_header &&
      <div id="calltoaction_1662791867849" className={`Heading_Text ${!GradientAnimation ? "Heading_Gradient" : ""}`}>{CallToActionConfig.section_header}</div>
      }
      {CallToActionConfig.section_sub_header &&
      <div id="calltoaction_1662791870770" className="Normal_Text" style={{paddingBottom:"var(--Text_Size_Five)"}}>{CallToActionConfig.section_sub_header}</div>
      }
      <div id="calltoaction_1662791872105">
        {
        CallToActionConfig.button_label
        ?
        CallToActionConfig.button_url_link
        ?
        <a id="calltoaction_1662791875593" href={CallToActionConfig.button_url_link} aria-label="CTA" target={button_url_new_tab && "_blank"} rel="noopener noreferrer"><button id="calltoaction_1662791892528" className="Floating">{CallToActionConfig.button_label}</button></a>
        :
        CallToActionConfig.button_page_link
        ?
        <Link id="calltoaction_1662791877625" exact="true" to={CallToActionConfig.button_page_link}><button id="calltoaction_1662791894064" className="Floating">{CallToActionConfig.button_label}</button></Link>
        :
        <button id="calltoaction_1662791883321" className="Floating" onClick={ModalState}>{CallToActionConfig.button_label}</button>
        :
        ''
        }
        <div id="calltoaction_1662791884273" className="Extra_Spacing" /><div id="calltoaction_1662792396938" className="Extra_Spacing" />
      </div>
    </section>
  </>
);

export default CallToAction